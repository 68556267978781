import * as Yup from 'yup'
import { phoneNumberRegex } from 'utils/validations'
import { isDateString } from 'utils/time'
import { DateTime } from 'luxon'
import { getAge, normalizePhoneNumber } from 'utils'

export const TIMESPAN_OPTIONS = [
  { value: 'week', label: 'Week' },
  { value: 'month', label: 'Month' }
]

export const CONTENT_VERTICALS = Object.freeze({
  GAMBLING_SPORTS: 'gambling_sports',
  GAMBLING_CASINO: 'gambling_casino',
  GAMBLING_HORSE_RACING: 'gambling_horse_racing',
  GAMBLING_STATS: 'gambling_stats',
  GAMING_GENERAL: 'gaming_general',
  GAMING_STREAMER: 'gaming_streamer',
  GAMING_MOBA: 'gaming_moba',
  GAMING_E_SPORTS: 'gaming_e_sports',
  FINANCE_PERSONAL_FINANCE: 'finance_personal_finance',
  FINANCE_EQUITIES: 'finance_equities',
  FINANCE_CRYPTO: 'finance_crypto',
  FINANCE_VC: 'finance_vc',
  FINANCE_MEMES: 'finance_memes',
  SPORTS_WNBA: 'sports_wnba',
  SPORTS_NBA: 'sports_nba',
  SPORTS_MLB: 'sports_mlb',
  SPORTS_NFL: 'sports_nfl',
  SPORTS_NHL: 'sports_nhl',
  SPORTS_SOCCER: 'sports_soccer',
  SPORTS_NCAA_BASKETBALL: 'sports_ncaa_basketball',
  SPORTS_NCAA_FOOTBALL: 'sports_ncaa_football',
  SPORTS_GOLF: 'sports_golf',
  SPORTS_COMBAT: 'sports_combat',
  SPORTS_CITY_SPECIFIC: 'sports_city_specific',
  SPORTS_STATS: 'sports_stats',
  ENTERTAINMENT_GENERAL: 'entertainment_general',
  ENTERTAINMENT_MUSIC: 'entertainment_music',
  ENTERTAINMENT_POP_CULTURE: 'entertainment_pop_culture',
  ENTERTAINMENT_REALITY_TV: 'entertainment_reality_tv',
  NEWS_GENERAL: 'news_general',
  NEWS_FINANCE: 'news_finance',
  NEWS_SPORTS: 'news_sports',
  NEWS_TECH: 'news_tech',
  NEWS_POLITICS: 'news_politics',
  FOOD_EATING_IT: 'food_eating_it',
  FOOD_COOKING_IT: 'food_cooking_it',
  TRAVEL_DOMESTIC: 'travel_domestic',
  TRAVEL_INTERNATIONAL: 'travel_international',
  TRAVEL_CONCERTS_LIVE_EVENTS: 'travel_concerts_live_events',
  OUTDOORS_HUNTING: 'outdoors_hunting',
  OUTDOORS_FISHING: 'outdoors_fishing',
  OUTDOORS_SURVIVAL: 'outdoors_survival',
  OUTDOORS_COMPETITIVE_SHOOTING: 'outdoors_competitive shooting',
  OUTDOORS_GENERAL: 'outdoors_general',
  RELATIONSHIPS_AND_DATING_FEMALE_VIEWPOINT: 'relationships_and_dating_female_viewpoint',
  RELATIONSHIPS_AND_DATING_MALE_VIEWPOINT: 'relationships_and_dating_male_viewpoint',
  COMEDY: 'comedy',
  MEMES: 'memes',
  TRUE_CRIME: 'true_crime',
  SCIENCE: 'science',
  SPACE: 'space',
  HISTORY: 'history',
  HEALTH_AND_FITNESS: 'health_and_fitness',
  STREETWEAR: 'streetwear',
  CITY_FOCUSED: 'city_focused'
})

export const CONTENT_VERTICAL_OPTIONS = Object.freeze([
  { value: CONTENT_VERTICALS.GAMING_GENERAL, group: 'Gambling', label: 'General' },
  { value: CONTENT_VERTICALS.GAMBLING_CASINO, group: 'Gambling', label: 'Casino' },
  { value: CONTENT_VERTICALS.GAMING_E_SPORTS, group: 'Gambling', label: 'E-Sports' },
  { value: CONTENT_VERTICALS.GAMBLING_HORSE_RACING, group: 'Gambling', label: 'Horse Racing' },
  { value: CONTENT_VERTICALS.GAMING_MOBA, group: 'Gambling', label: 'MOBA' },
  { value: CONTENT_VERTICALS.GAMBLING_SPORTS, group: 'Gambling', label: 'Sports' },
  { value: CONTENT_VERTICALS.GAMBLING_STATS, group: 'Gambling', label: 'Stats' },
  { value: CONTENT_VERTICALS.GAMING_STREAMER, group: 'Gambling', label: 'Streamer' },
  { value: CONTENT_VERTICALS.FINANCE_CRYPTO, group: 'Finance', label: 'Crypto' },
  { value: CONTENT_VERTICALS.FINANCE_EQUITIES, group: 'Finance', label: 'Equities' },
  { value: CONTENT_VERTICALS.FINANCE_MEMES, group: 'Finance', label: 'Memes' },
  { value: CONTENT_VERTICALS.FINANCE_PERSONAL_FINANCE, group: 'Finance', label: 'Personal Finance' },
  { value: CONTENT_VERTICALS.FINANCE_VC, group: 'Finance', label: 'VC' },
  { value: CONTENT_VERTICALS.SPORTS_CITY_SPECIFIC, group: 'Sports', label: 'City-Specific' },
  { value: CONTENT_VERTICALS.SPORTS_COMBAT, group: 'Sports', label: 'Combat' },
  { value: CONTENT_VERTICALS.SPORTS_GOLF, group: 'Sports', label: 'Golf' },
  { value: CONTENT_VERTICALS.SPORTS_NBA, group: 'Sports', label: 'NBA' },
  { value: CONTENT_VERTICALS.SPORTS_NCAA_BASKETBALL, group: 'Sports', label: 'NCAA Basketball' },
  { value: CONTENT_VERTICALS.SPORTS_NCAA_FOOTBALL, group: 'Sports', label: 'NCAA Football' },
  { value: CONTENT_VERTICALS.SPORTS_NFL, group: 'Sports', label: 'NFL' },
  { value: CONTENT_VERTICALS.SPORTS_NHL, group: 'Sports', label: 'NHL' },
  { value: CONTENT_VERTICALS.SPORTS_MLB, group: 'Sports', label: 'MLB' },
  { value: CONTENT_VERTICALS.SPORTS_SOCCER, group: 'Sports', label: 'Soccer' },
  { value: CONTENT_VERTICALS.SPORTS_STATS, group: 'Sports', label: 'Stats' },
  { value: CONTENT_VERTICALS.SPORTS_WNBA, group: 'Sports', label: 'WNBA' },
  { value: CONTENT_VERTICALS.ENTERTAINMENT_GENERAL, group: 'Entertainment', label: 'General' },
  { value: CONTENT_VERTICALS.ENTERTAINMENT_MUSIC, group: 'Entertainment', label: 'Music' },
  { value: CONTENT_VERTICALS.ENTERTAINMENT_POP_CULTURE, group: 'Entertainment', label: 'Pop Culture' },
  { value: CONTENT_VERTICALS.ENTERTAINMENT_REALITY_TV, group: 'Entertainment', label: 'Reality TV' },
  { value: CONTENT_VERTICALS.NEWS_GENERAL, group: 'News', label: 'General' },
  { value: CONTENT_VERTICALS.NEWS_FINANCE, group: 'News', label: 'Finance' },
  { value: CONTENT_VERTICALS.NEWS_POLITICS, group: 'News', label: 'Politics' },
  { value: CONTENT_VERTICALS.NEWS_SPORTS, group: 'News', label: 'Sports' },
  { value: CONTENT_VERTICALS.NEWS_TECH, group: 'News', label: 'Tech' },
  { value: CONTENT_VERTICALS.FOOD_COOKING_IT, group: 'Food', label: 'Cooking it' },
  { value: CONTENT_VERTICALS.FOOD_EATING_IT, group: 'Food', label: 'Eating it' },
  { value: CONTENT_VERTICALS.TRAVEL_CONCERTS_LIVE_EVENTS, group: 'Travel', label: 'Concerts/Live Events' },
  { value: CONTENT_VERTICALS.TRAVEL_DOMESTIC, group: 'Travel', label: 'Domestic' },
  { value: CONTENT_VERTICALS.TRAVEL_INTERNATIONAL, group: 'Travel', label: 'International' },
  { value: CONTENT_VERTICALS.OUTDOORS_GENERAL, group: 'Outdoors', label: 'General' },
  {
    value: CONTENT_VERTICALS.OUTDOORS_COMPETITIVE_SHOOTING,
    group: 'Outdoors',
    label: 'Competitive Shooting'
  },
  { value: CONTENT_VERTICALS.OUTDOORS_FISHING, group: 'Outdoors', label: 'Fishing' },
  { value: CONTENT_VERTICALS.OUTDOORS_HUNTING, group: 'Outdoors', label: 'Hunting' },
  { value: CONTENT_VERTICALS.OUTDOORS_SURVIVAL, group: 'Outdoors', label: 'Survival' },
  {
    value: CONTENT_VERTICALS.RELATIONSHIPS_AND_DATING_FEMALE_VIEWPOINT,
    group: 'Relationships & Dating',
    label: 'Female Viewpoint'
  },
  {
    value: CONTENT_VERTICALS.RELATIONSHIPS_AND_DATING_MALE_VIEWPOINT,
    group: 'Relationships & Dating',
    label: 'Male Viewpoint'
  },
  { value: CONTENT_VERTICALS.COMEDY, label: 'Comedy' },
  { value: CONTENT_VERTICALS.MEMES, label: 'Memes' },
  { value: CONTENT_VERTICALS.TRUE_CRIME, label: 'True Crime' },
  { value: CONTENT_VERTICALS.SCIENCE, label: 'Science' },
  { value: CONTENT_VERTICALS.SPACE, label: 'Space' },
  { value: CONTENT_VERTICALS.HISTORY, label: 'History' },
  { value: CONTENT_VERTICALS.HEALTH_AND_FITNESS, label: 'Health and Fitness' },
  { value: CONTENT_VERTICALS.STREETWEAR, label: 'Streetwear' },
  { value: CONTENT_VERTICALS.CITY_FOCUSED, label: 'City-Focused' }
])

export const CONTENT_FORMATS = Object.freeze({
  PODCAST: 'podcast',
  VIDEO: 'video',
  SOCIAL: 'social',
  NEWSLETTER: 'newsletter',
  BLOG: 'blog',
  STREAMING: 'streaming'
})

export const CONTENT_FORMAT_OPTIONS = Object.freeze([
  { value: CONTENT_FORMATS.PODCAST, label: 'Podcast' },
  { value: CONTENT_FORMATS.VIDEO, label: 'Video' },
  { value: CONTENT_FORMATS.SOCIAL, label: 'Social' },
  { value: CONTENT_FORMATS.BLOG, label: 'Blog' },
  { value: CONTENT_FORMATS.STREAMING, label: 'Streaming' }
])

export const PODCAST_CATEGORIES = Object.freeze({
  ARTS: 'arts',
  BUSINESS: 'business',
  COMEDY: 'comedy',
  DATING_RELATIONSHIPS_SEX: 'dating_relationships_sex',
  EDUCATION: 'education',
  FICTION: 'fiction',
  HEALTH_AND_FITNESS: 'health_and_fitness',
  HISTORY: 'history',
  LEISURE: 'leisure',
  MUSIC: 'music',
  NEWS: 'news',
  RELIGION_AND_SPIRITUALITY: 'religion_and_sprituality',
  SCIENCE: 'science',
  SOCIETY_AND_CULTURE: 'society_and_culture',
  SPORTS: 'sports',
  TECHNOLOGY: 'technology',
  TRUE_CRIME: 'true_crime',
  TV_AND_FILM: 'tv_and_film'
})

export const PODCAST_CATEGORY_OPTIONS = Object.freeze([
  { value: PODCAST_CATEGORIES.ARTS, label: 'Arts' },
  { value: PODCAST_CATEGORIES.BUSINESS, label: 'Business' },
  { value: PODCAST_CATEGORIES.COMEDY, label: 'Comedy' },
  { value: PODCAST_CATEGORIES.DATING_RELATIONSHIPS_SEX, label: 'Dating, Relationships, Sex' },
  { value: PODCAST_CATEGORIES.EDUCATION, label: 'Education' },
  { value: PODCAST_CATEGORIES.FICTION, label: 'Fiction' },
  { value: PODCAST_CATEGORIES.HEALTH_AND_FITNESS, label: 'Health & Fitness' },
  { value: PODCAST_CATEGORIES.HISTORY, label: 'History' },
  { value: PODCAST_CATEGORIES.LEISURE, label: 'Leisure' },
  { value: PODCAST_CATEGORIES.MUSIC, label: 'Music' },
  { value: PODCAST_CATEGORIES.NEWS, label: 'News' },
  { value: PODCAST_CATEGORIES.RELIGION_AND_SPIRITUALITY, label: 'Religion & Spirituality' },
  { value: PODCAST_CATEGORIES.SCIENCE, label: 'Science' },
  { value: PODCAST_CATEGORIES.SOCIETY_AND_CULTURE, label: 'Society & Culture' },
  { value: PODCAST_CATEGORIES.SPORTS, label: 'Sports' },
  { value: PODCAST_CATEGORIES.TECHNOLOGY, label: 'Technology' },
  { value: PODCAST_CATEGORIES.TRUE_CRIME, label: 'True Crime' },
  { value: PODCAST_CATEGORIES.TV_AND_FILM, label: 'TV & Film' }
])

export const CONDITIONAL_FIELD_OPTIONS = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' }
]

export const INITIAL_IDOL_VALUES = {
  first_name: '',
  last_name: '',
  birthday: '',
  email: '',
  phone_number: '',
  gender: '',
  address: {
    address1: '',
    city: '',
    state: '',
    zip: '',
    country: ''
  },
  in_college: '',
  has_podcast: '',
  has_video_show: '',
  on_twitter: '',
  on_instagram: '',
  on_tiktok: '',
  on_youtube: '',
  on_facebook: '',
  has_favorite_team: '',
  college_name: '',
  graduation_date: '',
  content_verticals: [],
  content_formats: [],
  aggregate_total_followers: '',
  podcast: {
    title: '',
    category: '',
    description: '',
    link: '',
    downloads_last_month: '',
    analytics_screenshot: ''
  },
  video: {
    title: '',
    description: '',
    link: '',
    views_last_month: '',
    analytics_screenshot: ''
  },
  twitter: {
    followers: '',
    screenshot: '',
    link: ''
  },
  instagram: {
    followers: '',
    screenshot: '',
    link: ''
  },
  tiktok: {
    followers: '',
    screenshot: '',
    link: ''
  },
  youtube: {
    followers: '',
    screenshot: '',
    link: ''
  },
  facebook: {
    followers: '',
    screenshot: '',
    link: ''
  },
  interests: {
    favorite_teams: [],
    additional_details: '',
    consumed_content: ''
  },
  demo_video: '',
  legal_consent: false
}

export const IDOL_VALIDATION = Yup.object().shape({
  // personal info
  first_name: Yup.string().trim().min(1, 'Please enter your first name').required('Field is required'),
  last_name: Yup.string().trim().min(1, 'Please enter your last name').required('Field is required'),
  birthday: Yup.string()
    .trim()
    .min(1, 'Please enter your birth date')
    .test('is date', 'Must be a valid date', (val) => !isNaN(new Date(val).getTime()))
    .test('is 18', 'Must be 18 years or older', (val) => getAge(val) >= 18)
    .required('Field is required'),
  email: Yup.string().email('Please enter a valid email').required('Email is required'),
  gender: Yup.string().nullable(),
  phone_number: Yup.string()
    .trim()
    .min(1, 'Phone number should not be empty')
    .matches(phoneNumberRegex, 'Phone number is not valid')
    .required('Field is required'),
  address: Yup.object().shape({
    address1: Yup.string().trim().min(1, 'Please enter a street address').required('Field is required'),
    city: Yup.string().trim().min(1, 'Please enter a city').required('Field is required'),
    zip: Yup.string().trim().min(1, 'Please enter a Zip Code').required('Field is required'),
    country: Yup.string().trim().min(1, 'Please select your country').required('Field is required'),
    state: Yup.string().when('country', (country, schema) => {
      return ['United States', 'Canada'].includes(country)
        ? schema.trim().min(1, 'Please choose a state').required('Field is required')
        : schema.nullable()
    })
  }),
  in_college: Yup.string().min(1, 'Field must be answered').required('Field is required'),
  college_name: Yup.string().when('in_college', {
    is: 'yes',
    then: Yup.string().min(1, 'Please enter your college name').required('Field is required'),
    otherwise: Yup.string().nullable()
  }),
  graduation_date: Yup.string().when('in_college', {
    is: 'yes',
    then: Yup.string()
      .trim()
      .min(1, 'Please enter your graduation date')
      .test('is date', 'Must be a valid date', isDateString),
    otherwise: Yup.string().nullable()
  }),
  // content
  content_verticals: Yup.array().min(1, 'Please select at least one option').required('Field is required'),
  content_formats: Yup.array().min(1, 'Please select at least one option').required('Field is required'),
  has_podcast: Yup.string().min(1, 'Field must be answered').required('Field is required'),
  podcast: Yup.object().when('has_podcast', {
    is: 'yes',
    then: Yup.object().shape({
      title: Yup.string().min(1, 'Please enter a title').required('Field is required'),
      category: Yup.string().min(1, 'Please choose a category').required('Field is required'),
      description: Yup.string().min(1, 'Please enter a description').required('Field is required'),
      link: Yup.string().trim().min(1, 'Field is required').url('Must be a valid url').required('Field is required'),
      downloads_last_month: Yup.number('Please enter a count').required('Field is required'),
      analytics_screenshot: Yup.string()
        .trim()
        .min(1, 'Field is required')
        .url('Please upload a photo')
        .required('Field is required')
    }),
    otherwise: Yup.object().nullable()
  }),
  has_video_show: Yup.string().min(1, 'Field must be answered').required('Field is required'),
  video: Yup.object().when('has_video_show', {
    is: 'yes',
    then: Yup.object().shape({
      title: Yup.string().min(1, 'Please enter a title').required('Field is required'),
      description: Yup.string().min(1, 'Please enter a description').required('Field is required'),
      link: Yup.string().trim().min(1, 'Field is required').url('Must be a valid url').required('Field is required'),
      views_last_month: Yup.number('Please enter a count').required('Field is required'),
      analytics_screenshot: Yup.string()
        .trim()
        .min(1, 'Field is required')
        .url('Please upload a photo')
        .required('Field is required')
    }),
    otherwise: Yup.object().nullable()
  }),
  aggregate_total_followers: Yup.number().required('Please enter a count'),
  on_twitter: Yup.string().min(1, 'Field must be answered').required('Field is required'),
  twitter: Yup.object().when('on_twitter', {
    is: 'yes',
    then: Yup.object().shape({
      followers: Yup.number('Please enter a count').required('Field is required'),
      screenshot: Yup.string()
        .trim()
        .min(1, 'Field is required')
        .url('Please upload a photo')
        .required('Field is required'),
      link: Yup.string().trim().min(1, 'Field is required').url('Must be a valid url').required('Field is required')
    }),
    otherwise: Yup.object().nullable()
  }),
  on_instagram: Yup.string().min(1, 'Field must be answered').required('Field is required'),
  instagram: Yup.object().when('on_instagram', {
    is: 'yes',
    then: Yup.object().shape({
      followers: Yup.number('Please enter a count').required('Field is required'),
      screenshot: Yup.string()
        .trim()
        .min(1, 'Field is required')
        .url('Please upload a photo')
        .required('Field is required'),
      link: Yup.string().trim().min(1, 'Field is required').url('Must be a valid url').required('Field is required')
    }),
    otherwise: Yup.object().nullable()
  }),
  on_tiktok: Yup.string().min(1, 'Field must be answered').required('Field is required'),
  tiktok: Yup.object().when('on_tiktok', {
    is: 'yes',
    then: Yup.object().shape({
      followers: Yup.number('Please enter a count').required('Field is required'),
      screenshot: Yup.string()
        .trim()
        .min(1, 'Field is required')
        .url('Please upload a photo')
        .required('Field is required'),
      link: Yup.string().trim().min(1, 'Field is required').url('Must be a valid url').required('Field is required')
    }),
    otherwise: Yup.object().nullable()
  }),
  on_youtube: Yup.string().min(1, 'Field must be answered').required('Field is required'),
  youtube: Yup.object().when('on_youtube', {
    is: 'yes',
    then: Yup.object().shape({
      followers: Yup.number('Please enter a count').required('Field is required'),
      screenshot: Yup.string()
        .trim()
        .min(1, 'Field is required')
        .url('Please upload a photo')
        .required('Field is required'),
      link: Yup.string().trim().min(1, 'Field is required').url('Must be a valid url').required('Field is required')
    }),
    otherwise: Yup.object().nullable()
  }),
  on_facebook: Yup.string().min(1, 'Field must be answered').required('Field is required'),
  facebook: Yup.object().when('on_facebook', {
    is: 'yes',
    then: Yup.object().shape({
      followers: Yup.number('Please enter a count').required('Field is required'),
      screenshot: Yup.string()
        .trim()
        .min(1, 'Field is required')
        .url('Please upload a photo')
        .required('Field is required'),
      link: Yup.string().trim().min(1, 'Field is required').url('Must be a valid url').required('Field is required')
    }),
    otherwise: Yup.object().nullable()
  }),
  // interests
  has_favorite_team: Yup.string().min(1, 'Field must be answered').required('Field is required'),
  interests: Yup.object().when('has_favorite_team', {
    is: 'yes',
    then: Yup.object().shape({
      favorite_teams: Yup.array().min(1, 'Please choose at least one team').required('Field is required'),
      additional_details: Yup.string().nullable(),
      consumed_content: Yup.string().nullable()
    }),
    otherwise: Yup.object().shape({
      favorite_teams: Yup.array().nullable(),
      additional_details: Yup.string().nullable(),
      consumed_content: Yup.string().nullable()
    })
  }),
  demo_video: Yup.string().nullable().url('Must be a valid video upload')
})

export function transformApplicantRequest(data) {
  const {
    first_name,
    last_name,
    birthday,
    email,
    phone_number,
    gender,
    address,
    in_college,
    college_name,
    graduation_date,
    content_verticals,
    content_formats,
    aggregate_total_followers,
    podcast,
    video,
    twitter,
    instagram,
    tiktok,
    youtube,
    facebook,
    interests,
    demo_video,
    has_podcast,
    has_video_show,
    on_twitter,
    on_instagram,
    on_tiktok,
    on_youtube,
    on_facebook
  } = data
  const applicant = {
    content_formats,
    content_verticals,
    aggregate_total_followers: Number(aggregate_total_followers),
    twitter:
      on_twitter === 'no'
        ? undefined
        : {
            ...twitter,
            followers: Number(twitter.followers)
          },
    instagram:
      on_instagram === 'no'
        ? undefined
        : {
            ...instagram,
            followers: Number(instagram.followers)
          },
    tiktok:
      on_tiktok === 'no'
        ? undefined
        : {
            ...tiktok,
            followers: Number(tiktok.followers)
          },
    youtube:
      on_youtube === 'no'
        ? undefined
        : {
            ...youtube,
            followers: Number(youtube.followers)
          },
    facebook:
      on_facebook === 'no'
        ? undefined
        : {
            ...facebook,
            followers: Number(facebook.followers)
          },
    demo_video,
    currently_in_college: in_college === 'yes' ? true : false,
    college_name: in_college === 'yes' ? college_name : undefined,
    graduation_date: in_college === 'yes' ? DateTime.fromISO(graduation_date).setZone().toISO() : undefined,
    interests,
    podcast:
      has_podcast === 'no'
        ? undefined
        : {
            ...podcast,
            downloads_last_month: Number(podcast.downloads_last_month)
          },
    video:
      has_video_show === 'no'
        ? undefined
        : {
            ...video,
            views_last_month: Number(video.views_last_month)
          }
  }
  return {
    first_name,
    last_name,
    birthday,
    email,
    phone_number: normalizePhoneNumber(phone_number),
    gender,
    address,
    applicant
  }
}
