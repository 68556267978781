import validator from 'validator'

export const formatSocialHandle = (handle = '') => {
  if (typeof handle === 'string' && handle.charAt(0) === '@') {
    return handle.substring(1)
  }
  return handle
}

export const reverseDateString = (dateStr) => {
  if (!dateStr) return dateStr
  if (dateStr.indexOf('-') >= 0) {
    return dateStr.split('-').reverse().join('/')
  } else {
    return dateStr.split('/').reverse().join('-')
  }
}

export function prefixUrl(value) {
  try {
    if (!/^(http|https):\/\//.test(value)) {
      const prefixedUrlString = new URL(`http://${value}`).href
      if (validator.isURL(prefixedUrlString)) return prefixedUrlString
    } else return value
  } catch {
    //ignore
    return value
  }
  return value
}

export function formatCurrency(value, currency = 'USD') {
  // Create our number formatter.
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: 2, // requires at least 2 digits, 2500.1 would be $2,500.10
    maximumFractionDigits: 2 // constrains to a max of 2 digits 2500.111 would be $2,500.11
  })

  return formatter.format(typeof value === 'string' ? Number(value) : value) /* $2,500.00 */
}

export function formatUSD(value) {
  return formatCurrency(value, 'USD')
}

export function parsePadding(paddingString) {
  const splitPaddingString = paddingString.split(' ')

  const padding = { top: '0rem', right: '0rem', bottom: '0rem', left: '0rem' }
  let paddingSides
  switch (splitPaddingString.length) {
    case 1:
      const [allSides] = splitPaddingString
      paddingSides = [allSides, allSides, allSides, allSides]
      break
    case 2:
      const [yPadding, xPadding] = splitPaddingString
      paddingSides = [yPadding, xPadding, yPadding, xPadding]
      break
    case 3:
      const [topPadding, lrPadding, bottomPadding] = splitPaddingString
      paddingSides = [topPadding, lrPadding, bottomPadding, lrPadding]
      break
    case 4:
      const [tPadding, rPadding, bPadding, lPadding] = splitPaddingString
      paddingSides = [tPadding, rPadding, bPadding, lPadding]
      break
  }

  const [tPadding, rPadding, bPadding, lPadding] = paddingSides ?? []
  padding.top = tPadding
  padding.right = rPadding
  padding.bottom = bPadding
  padding.left = lPadding

  return padding
}

export function shortId() {
  return Math.random().toString(16).split('.')[1]
}

export function normalizePhoneNumber(phone) {
  if (typeof phone !== 'string') return false
  phone = phone.replace(/\s/g, '') // remove all spaces
  const internationalRegex = /^\+(?:[0-9] ?){6,14}[0-9]$/
  if (internationalRegex.test(phone)) {
    return phone.startsWith('+') ? phone : '+' + phone
  }
  const usRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
  if (usRegex.test(phone)) {
    return '+1' + phone
  }
  return false
}
